import { loginHelper } from '@/main';
import { Component } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import PageRender from '@/models/PageRender';
import { profileModule } from '@/store/modules/profile';
import Vue from 'vue';

@Component
export default class LoginComponent extends PageRender {
    public $refs!: {
        forgotPasswordModal: BModal;
        resetPasswordModal: BModal;
        registerModal: BModal;
    };

    public async mounted() {
        if (loginHelper.isLoggedIn()) {
            return this.$router.push('/');
        }

        if (this.$route.query.passwordResetCode) {
            this.$refs.resetPasswordModal.show();
        }

        const redirectUriStorage = window.localStorage;
        if (this.$route.query.redirectUrl) {
            redirectUriStorage.setItem('redirectUri', this.$route.query.redirectUrl as string);

            await this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    redirectUrl: undefined,
                },
            });
        }
    }

    public async login(evt: any) {
        evt.preventDefault();
        await profileModule.login();
    }

    public goToManangePassword() {
        window.open(`${Vue.$env().identityEndpoint}Account/ForgotPassword`, '_blank');
    }
}
